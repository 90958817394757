.edgebutton {
  width: 45px;
  height: 45px;
  background: #fff;
  border: 1px solid #ffffff;
  cursor: pointer;
  border-radius: 20%;
  transition: all 0.2s;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  scale: 1.2;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}