.react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    /* Uncomment for arrows handle */
    /* background-repeat: no-repeat; */
    /* background-origin: content-box; */
    /* box-sizing: border-box; */
    /* background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 84.35 85.21'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:13px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='6.5 51.15 6.5 6.5 51.67 6.5'/%3E%3Cpolyline class='cls-1' points='77.85 36.57 77.85 78.71 32.82 78.71'/%3E%3C/svg%3E"); */
    background-position: center center;
    border-radius: 10px;
    opacity: 0.5;
    transition: all 0.2s;
  }

  .react-resizable-handle:hover {
    opacity: 1;
    scale: 1.02;
  }

  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    top: 0;
    /* left: 0; */
    /* transform: translateX(50%) ; */
    /* rotate(135deg); Uncomment for arrows*/ 
    width: 4px;
    height: 95%;
    color: transparent;
  }
  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    cursor: ns-resize;
    transform: rotate(0deg);
    width: 95%;
    height: 4px;
    color: transparent;
  }
  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle-s {
  }