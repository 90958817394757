@tailwind base;
@tailwind components;
@tailwind utilities;

/*
Enable the scroll wheel everywhere to avoid inconsistencies with the scroll bar popping in and out
*/
html {
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  /* background-color: rgb(37, 37, 46); */
  border-radius: 10%;
  color: white;
  padding: 0.2rem;
}

mark {
  border-radius: 2px;
  background-color: #fcf6bd2f;
  color: white;
}
