.app {
  overflow-x: hidden;
}

.navbar-scroll-offset {
  margin-top: -50px;
  padding-top: 50px;
}

.test-bg {
  background-color: #2b2b2b;
}

