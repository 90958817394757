/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
}

.accordion__item + .accordion__item {
}

.accordion__button {
    background-color: #3c3c3c;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    transition: all 0.2s;
    font-weight: bold;
}

.accordion__button:hover {
    background-color: #606060;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
    transition: all 0.2s;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg) translateY(-50%);
}

[hidden] {
    display: none;
}

.accordion__panel {
    animation: fadein 0.35s ease-in;
}

.active {
    background-color: #2563eb;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
